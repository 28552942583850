<script>
import GlobalVue from '@root/helper/Global.vue';
import Gen from '@helper/Gen';
export default {
    name: "NavBar",
    extends: GlobalVue,
    mounted(){
        window.nav = this
    },
    methods:{
        logout(){
            this.page.logout()
        }
    }
};
</script>
<template>
<nav class="navbar navbar-default navbar-static-top m-b-0">
    <div class="navbar-header">
        <a class="navbar-toggle hidden-sm hidden-md hidden-lg " href="javascript:void(0)" data-toggle="collapse" data-target=".navbar-collapse"><i class="ti-menu"></i></a>
        <div class="top-left-part">
            <router-link class="logo" :to="{name:'BoDashboard'}">
                <!-- <VImg style="width: 50%;" :src="baseUrl+'/frontend/images/logo-dark.png'" alt="Farmsco"></VImg> -->
                  <img class="small-logo" :src="assets('bo_images','bo-logo-sm.png')" alt="home" />
                  <img class="normal-logo" :src="assets('bo_images','bo-logo.png')" alt="home" />
            </router-link>
        </div>
        <ul class="nav navbar-top-links navbar-left hidden-xs">
            <li><a href="javascript:void(0)" class="open-close hidden-xs waves-effect waves-light"><i class="icon-arrow-left-circle ti-menu"></i></a></li>
            <!-- <li v-if="!page.NoSearch">
                <VForm @resp="page.search" role="search" class="app-search hidden-xs">
                <input type="text" v-model="page.filter.search" placeholder="Search..." class="form-control">
                <a href="#"><i class="fa fa-search"></i></a>
                </VForm>
            </li> -->
        </ul>
        <ul class="nav navbar-top-links navbar-right pull-right">
            <li class="dropdown" v-if="user">
                <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#"> <VImg :src="assets('bo_images','user.jpg')"  :lazy-src="uploader(user.bu_pic,'50x50c')" alt="user-img" width="36" class="img-circle"></VImg><b class="hidden-xs">{{user.bu_full_name}}</b> </a>
                <ul class="dropdown-menu dropdown-user animated flipInY">
                    <li><router-link :to="{name:'BoProfile'}"><i class="ti-user"></i> My Profile</router-link></li>
                    <li><a href="javascript:;" @click="logout()"><i class="fa fa-power-off"></i> Logout</a></li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
</template>