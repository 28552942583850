<script>
import NavBar from '@backend/part/NavBar.vue';
import SideBar from '@backend/part/SideBar.vue';
import RightSideBar from '@backend/part/RightSideBar.vue';
import GlobalVue from '@helper/Global.vue';

export default {
  extends: GlobalVue,
  name:"BoLayout",
  components:{
    SideBar,
    NavBar,
    RightSideBar
  },
  computed:{
    yearcopy(){
      return Gen.yearCopy(this.web.yearcopy)
    }
  },
  mounted(){
    this.$set(this.$root, 'base', this)
  }
};
</script>

<template>
  <section id="wrapper">
    <NavBar></NavBar>
    <SideBar></SideBar>
    <div id="page-wrapper">
      <router-view/>
    </div>
    <RightSideBar></RightSideBar>
    <!-- Footer -->
    <footer class="footer text-center inspect"> {{yearcopy}} &copy; Farmsco Official Website. All Rights Reserved. </footer>
    <FileManager></FileManager>
    <ImageFilter></ImageFilter>
  </section>
</template>