<script>
import GlobalVue from '@root/helper/Global.vue';

export default {
  extends: GlobalVue,
  name: "RightSideBar",
};
</script>

<template>
</template>