<script>
import GlobalVue from '@root/helper/Global.vue';
import BoConfig from '@root/config/BoConfig';
import Gen from '../../helper/Gen';
export default {
name: "SideBar",
extends: GlobalVue,
async mounted(){
await Gen.loadScript("/plugins/sidebar-nav/dist/sidebar-nav.min.js")
this.initMenu()
},
computed:{
menus(){
return BoConfig.sidebar
}
},
methods:{
logout(){
this.page.logout()
},
initMenu(){
setTimeout(()=>{
$('#side-menu').metisMenu()
$("#side-menu li,#side-menu a").removeClass("active").find("ul").removeClass("in")
var parentUl = $(".router-link-active").parents("ul");
parentUl.addClass("in").height("auto")
parentUl.prev().addClass("active")
// remove parent with no child
$.each($(".nav-second-level"), function(){
if($(this).find("li").length==0){
$(this).closest("li").remove()
}
})
// remove divider with no link
$.each($(".nav-small-cap"), function(){
if(!$(this).next().is(":not(.nav-small-cap)")){
$(this).remove()
}
})
}, 100)
},
haveRoute(link){
let l = this.$router.resolve({name: link});
if( l.resolved.matched.length > 0) return true
return false
},
haveTargetBlank(link){
if(link.indexOf("http")==-1) return false
return link.indexOf(location.host)>-1 ? false : "_blank"
}
},
watch:{
'$route.name'(){
this.initMenu()
}
}
};
</script>
<template>
<div class="navbar-default sidebar" role="navigation">
    <div class="sidebar-nav navbar-collapse slimscrollsidebar">
        <ul class="nav" id="side-menu">
            <!-- <li class="sidebar-search hidden-sm hidden-md hidden-lg">
                <div class="input-group custom-search-form">
                    <input type="text" class="form-control" placeholder="Search...">
                    <span class="input-group-btn">
                        <button class="btn btn-default" type="button"> <i class="fa fa-search"></i> </button>
                    </span>
                </div>
            </li> -->
            <!-- User profile-->
            <li class="user-pro">
                <a href="#" class="waves-effect"><VImg :src="assets('bo_images','user.jpg')" :lazy-src="uploader(user.bu_pic,'50x50c')" alt="user-img"  class="img-circle"></VImg><span class="hide-menu"> {{user.bu_full_name}}<span class="fa arrow"></span></span></a>
                <ul class="nav nav-second-level">
                    <li><router-link :to="{name:'BoProfile'}"><i class="ti-user"></i> My Profile</router-link></li>
                    <li><a href="javascript:;" @click="logout()"><i class="fa fa-power-off"></i> Logout</a></li>
                </ul>
            </li>
            <!-- User profile-->
            <template v-for="(v,k) in menus">
            <li v-if="v.divider" class="nav-small-cap m-t-10" :key="k">--- {{v.name}}</li>
            <li v-else-if="!v.childs" :key="k">
                <router-link v-if="page.menuRole(v.link)&&haveRoute(v.link)" :to="{name:v.link}" class="waves-effect">
                <i :class="v.icon"></i> <span class="hide-menu">{{v.name}} </span>
                </router-link>
                <a v-else :href="v.link" :target="haveTargetBlank(v.link)"><i :class="v.icon"></i> <span class="hide-menu">{{v.name}} </span></a>
            </li>
            <li v-else :key="k">
                <a href="javascript:;" class="waves-effect">
                    <i :class="v.icon"></i>
                    <span class="hide-menu">{{v.name}}<span class="fa arrow"></span>
                </span>
            </a>
            <ul class="nav nav-second-level">
                <template v-for="(v2,k2) in v.childs">
                <li v-if="page.menuRole(v2.link)" :key="k2">
                    <router-link v-if="haveRoute(v2.link)" :to="{name: v2.link}">{{v2.name}}</router-link>
                    <a v-else :href="v2.link" :target="haveTargetBlank(v2.link)">{{v2.name}}</a>
                </li>
                </template>
            </ul>
        </li>
        </template>
    </ul>
</div>
</div>
</template>